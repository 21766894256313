import React from "react"

import { Heading1, SmallText } from "../../components/common"

// const apple = require("../../images/apple-app-store-button.png")
const apple = require("../../images/testflight-button.png")
const google = require("../../images/google-play-store-button.png")

export default function AppStoreDownload() {
  return (
    <div>
      <Heading1 className="text-primary text-3xl" style={{ lineHeight: 1 }}>
        DOWNLOAD FOR FREE
      </Heading1>
      <div>
        <SmallText>
          <b>alvyApp</b> is currently in open Beta on both <b>iOS</b> and&nbsp;
          <b>Android</b>, select one of the links below to start learning
        </SmallText>
      </div>
      <div className="flex flex-wrap justify-start items-start pt-3 md:flex-row">
        <a
          className="mt-2 mb-3 mr-3"
          href="https://testflight.apple.com/join/gcOV2NHQ"
          target="_blank"
        >
          <img src={apple} style={{ width: 164 }} />
        </a>
        <a
          className="mt-2"
          href="https://play.google.com/apps/testing/com.alvyapp"
          target="_blank"
        >
          <img src={google} style={{ width: 164 }} />
        </a>
      </div>
    </div>
  )
}
