import React from "react"

import SEO from "../components/Seo"
import PageWrapper from "../components/PageWrapper"

import Home from "../features/Home"

const IndexPage = ({ location }) => {
  return (
    <PageWrapper location={location}>
      <SEO title="alvyApp - Learning that lasts" />
      <Home />
    </PageWrapper>
  )
}

export default IndexPage
