import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./app-display.css"

import TodayComplete from "../../components/AppScreen/TodayComplete"

const BackgroundImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "backgroundPattern.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Img fluid={data.placeholderImage.childImageSharp.fluid} style={style} />
  )
}

export default function AppDisplay() {
  return (
    <div className="AppDisplay md:pt-8">
      <div className="relative md:flex md:justify-center md:align-center">
        <div className="AppDisplayBgImage">
          <BackgroundImage />
        </div>
        <div className="AppDisplayScreenShot">
          <TodayComplete />
        </div>
      </div>
    </div>
  )
}
