import React from "react"

import { Heading2, Text } from "../../components/common"
import AppNameImage from "./AppNameImage"

import AppStoreDownload from "../../components/AppStoreDownload"
import { Button } from "../../components/common"
import AppDisplay from "./AppDisplay"

import useNav from "../../hooks/useNav"

const Home = () => {
  const { navToFeatures } = useNav()
  return (
    <div className="pt-4 md:pt-0 px-4 pb-12">
      <div className="md:flex md:flex-row-reverse">
        <div className="md:p-12 md:w-3/6 md:flex md:flex-col md:justify-center md:align-center">
          <AppNameImage style={{ maxWidth: 180 }} />
          <div className="pt-2 pb-2">
            <Heading2>learning that lasts</Heading2>
          </div>
          <div className="pb-2">
            <Text>We make it easier for learners to learn.</Text>
          </div>
          <div className="pb-2">
            <Text>
              <b>alvyApp</b> helps commit information to long-term memory by
              hacking the brain using spaced repetition learning.
            </Text>
          </div>
          <div className="pb-2">
            <Text>
              In just 2 minutes a day, you can commit that hard to remember
              stuff to your long term memory.
            </Text>
          </div>
          <div className="hidden flex justify-center pt-4 md:block">
            <Button className="w-full md:w-auto" onClick={navToFeatures}>
              Explore App Features
            </Button>
          </div>
        </div>
        <div className="md:flex-1">
          <div className="pt-4 pb-6 md:pt-6 md:pb-10">
            <AppDisplay />
          </div>
        </div>
      </div>
      <div className="flex justify-center pb-6 md:hidden">
        <Button className="w-full md:w-auto" onClick={navToFeatures}>
          Explore App Features
        </Button>
      </div>
      <div className="pt-4 pb-4">
        <AppStoreDownload />
      </div>
    </div>
  )
}

export default Home
